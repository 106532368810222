import React, {
  ReactElement,
  useRef,
  useState,
} from 'react';
import './index.scss';

import Modal from '../Modal';
import withSendbirdContext from '../../lib/SendbirdSdkContext';

import Input, { InputLabel } from '../Input';
import Avatar from '../Avatar';
import Icon, { IconTypes } from '../Icon';
import { Type as ButtonType } from '../Button/type';
import Label, { LabelColors, LabelTypography, LabelStringSet } from '../Label';
import TextButton from '../TextButton';
import { SendbirdTypes } from '../../types';
import { noop } from '../../utils/utils';

interface Props {
  user: SendbirdTypes['User'];
  onCancel(): void;
  onSubmit(newFile: File, newNickname: string): void;
  onThemeChange?(theme: string): void;
  theme?: string;
  changeTheme?(theme: string): void;
}

export function EditUserProfile({
  user,
  onCancel,
  onSubmit,
  onThemeChange = null,
  theme = 'light',
  changeTheme = noop,
}: Props): ReactElement {
  const hiddenInputRef = useRef(null);
  const inputRef = useRef(null);
  const formRef = useRef(null);

  const [currentImg, setCurrentImg] = useState(null);
  const [newFile, setNewFile] = useState(null);

  return (
    <Modal
      titleText={LabelStringSet.EDIT_PROFILE__TITLE}
      submitText={LabelStringSet.BUTTON__SAVE}
      onCancel={onCancel}
      onSubmit={() => {
        if (user.nickname !== '' && !inputRef.current.value) {
          if (formRef.current.reportValidity) { // might not work in explorer
            formRef.current.reportValidity();
          }
          return;
        }
        onSubmit(inputRef.current.value, newFile);
        onCancel();
      }}
      type={ButtonType.PRIMARY}
    >
      <form
        className="sendbird__edit-user-profile"
        ref={formRef}
        onSubmit={(e) => { e.preventDefault(); }}
      >
        <section className="sendbird__edit-user-profile__img">
          <InputLabel>
            {LabelStringSet.EDIT_PROFILE__IMAGE_LABEL}
          </InputLabel>
          <div className="sendbird__edit-user__avatar">
            <Avatar
              height="80px"
              width="80px"
              src={currentImg || user.profileUrl}
            />
          </div>
          <input
            ref={hiddenInputRef}
            type="file"
            accept="image/gif, image/jpeg, image/png"
            style={{ display: 'none' }}
            onChange={(e) => {
              setCurrentImg(URL.createObjectURL(e.target.files[0]));
              setNewFile(e.target.files[0]);
              hiddenInputRef.current.value = '';
            }}
          />
          <TextButton
            className="sendbird__edit-user__avatar-button"
            onClick={() => hiddenInputRef.current.click()}
            notUnderline
          >
            <Label type={LabelTypography.BUTTON_1} color={LabelColors.PRIMARY}>
              {LabelStringSet.EDIT_PROFILE__IMAGE_UPLOAD}
            </Label>
          </TextButton>
        </section>
        <section className="sendbird__edit-user__name">
          <InputLabel>
            {LabelStringSet.EDIT_PROFILE__NICKNAME_LABEL}
          </InputLabel>
          <Input
            required={user.nickname !== ''}
            name="sendbird__edit-user__name"
            ref={inputRef}
            value={user.nickname}
            placeHolder={LabelStringSet.EDIT_PROFILE__NICKNAME_PLACEHOLDER}
          />
        </section>
        <section className="sendbird__edit-user__userid">
          <InputLabel>
            {/*  userID */}
            {LabelStringSet.EDIT_PROFILE__USERID_LABEL}
          </InputLabel>
          <Input
            disabled
            name="sendbird__edit-user__userid"
            value={user.userId}
          />
        </section>
        <section className="sendbird__edit-user__theme">
          <InputLabel>
            {LabelStringSet.EDIT_PROFILE__THEME_LABEL}
          </InputLabel>
          <div className="sendbird__edit-user__theme-icon">
            {
              theme === 'dark'
                ? (
                  <Icon
                    onClick={() => {
                      changeTheme('light');
                      if (onThemeChange && typeof onThemeChange === 'function') {
                        onThemeChange('light');
                      }
                    }}
                    type={IconTypes.TOGGLE_ON}
                    width={44}
                    height={24}
                  />
                )
                : (
                  <Icon
                    onClick={() => {
                      changeTheme('dark');
                      if (onThemeChange && typeof onThemeChange === 'function') {
                        onThemeChange('dark');
                      }
                    }}
                    type={IconTypes.TOGGLE_OFF}
                    width={44}
                    height={24}
                  />
                )
            }
          </div>
        </section>
      </form>
    </Modal>
  );
}

const mapStoreToProps = (store) => {
  return {
    theme: store.config.theme,
    changeTheme: store.config.setCurrenttheme,
  };
};

interface ConnectedEditUserProfileProps {
  user: SendbirdTypes['User'];
  onCancel(): void;
  onSubmit(newFile: File, newNickname: string): void;
  onThemeChange?(theme: string): void;
}

const ConnectedEditUserProfile: (
  props: ConnectedEditUserProfileProps
) => React.Component = withSendbirdContext(EditUserProfile, mapStoreToProps);

export default ConnectedEditUserProfile;
