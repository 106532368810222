import React from 'react';
import PropTypes from 'prop-types';

import ImageRenderer from '../ImageRenderer';
import DefaultAvatarLight from '../../svgs/icon-avatar-light.svg';
import DefaultAvatarDark from '../../svgs/icon-avatar-dark.svg';

import './index.scss';
import { uuidv4 } from '../../utils/uuid';

const defaultComponent = ({ width, height }) => () => (
  document.querySelector('.sendbird-theme--dark')
    ? <DefaultAvatarDark className="sendbird-avatar-img" style={{ height, width }} />
    : <DefaultAvatarLight className="sendbird-avatar-img" style={{ height, width }} />
);
const imageRendererClassName = 'sendbird-avatar-img';

export const AvatarInner = ({
  src,
  height,
  width,
  alt,
}) => {
  if (typeof src === 'string') {
    return (
      <ImageRenderer
        className={imageRendererClassName}
        url={src}
        height={height}
        width={width}
        alt={alt}
        defaultComponent={defaultComponent({ height, width })}
      />
    );
  }

  if (src && src.length) {
    if (src.length === 1) {
      return (
        <ImageRenderer
          className={imageRendererClassName}
          url={src[0]}
          height={height}
          width={width}
          alt={alt}
          defaultComponent={defaultComponent({ height, width })}
        />
      );
    }

    if (src.length === 2) {
      return (
        <div className="sendbird-avatar--inner__two-child">
          <ImageRenderer
            className={imageRendererClassName}
            url={src[0]}
            height={height}
            width={width}
            alt={alt}
            defaultComponent={defaultComponent({ height, width })}
          />
          <ImageRenderer
            className={imageRendererClassName}
            url={src[1]}
            height={height}
            width={width}
            alt={alt}
            defaultComponent={defaultComponent({ height, width })}
          />
        </div>
      );
    }

    if (src.length === 3) {
      return (
        <>
          <div className="sendbird-avatar--inner__three-child--upper">
            <ImageRenderer
              className={imageRendererClassName}
              url={src[0]}
              height={height}
              width={width}
              alt={alt}
              defaultComponent={defaultComponent({ height, width })}
            />
          </div>
          <div className="sendbird-avatar--inner__three-child--lower">
            <ImageRenderer
              className={imageRendererClassName}
              url={src[1]}
              height={height}
              width={width}
              alt={alt}
              defaultComponent={defaultComponent({ height, width })}
            />
            <ImageRenderer
              className={imageRendererClassName}
              url={src[2]}
              height={height}
              width={width}
              alt={alt}
              defaultComponent={defaultComponent({ height, width })}
            />
          </div>
        </>
      );
    }

    return (
      <div className="sendbird-avatar--inner__four-child">
        {
          src.slice(0, 4)
            .map((i) => (
              <ImageRenderer
                className={imageRendererClassName}
                url={i}
                height={height}
                width={width}
                alt={alt}
                key={uuidv4()}
                defaultComponent={defaultComponent({ height, width })}
              />
            ))
        }
      </div>
    );
  }
  // default img
  return (
    <ImageRenderer
      className={imageRendererClassName}
      url=""
      height={height}
      width={width}
      alt={alt}
      defaultComponent={defaultComponent({ height, width })}
    />
  );
};

AvatarInner.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  alt: PropTypes.string,
};

AvatarInner.defaultProps = {
  src: '',
  alt: '',
};

function Avatar({
  src,
  height,
  width,
  alt,
  className,
  onClick,
}, ref) {
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  return (
    <div
      className={
        [
          ...injectingClassName,
          'sendbird-avatar',
        ].join(' ')
      }
      ref={ref}
      style={{ height, width }}
      onClick={onClick}
      role="button"
      tabIndex="0"
      onKeyDown={onClick}
    >
      <AvatarInner src={src} height={height} width={width} alt={alt} />
    </div>
  );
}

Avatar.propTypes = {
  src: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  alt: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Avatar.defaultProps = {
  height: '56px',
  width: '56px',
  onClick: () => {},
  alt: '',
  src: '',
  className: '',
};

export default React.forwardRef(Avatar);
