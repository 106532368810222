import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  OG_MESSAGE,
  OUTGOING_OG_MESSAGE,
  INCOMING_OG_MESSAGE,
} from './const';
import {
  getSenderName,
  copyToClipboard,
  checkOGIsEnalbed,
  getMessageCreatedAt,
  getIsSentFromStatus,
  getSenderProfileUrl,
} from './utils';
import { UserProfileContext } from '../../lib/UserProfileContext';
import useMemoizedMessageText from './memoizedMessageText';
import './index.scss';

import UserProfile from '../UserProfile';
import IconButton from '../IconButton';
import ImageRenderer from '../ImageRenderer';
import MessageStatus from '../MessageStatus';
import EmojiReactions from '../EmojiReactions';
import Icon, { IconTypes, IconColors } from '../Icon';
import Label, { LabelTypography, LabelColors } from '../Label';
import ContextMenu, { MenuItem, MenuItems } from '../ContextMenu';
import Avatar from '../Avatar';

import useMouseHover from '../../hooks/onMouseHover';

const GROUPING_PADDING = '1px';
const NORAML_PADDING = '8px';

const OGMessageSwitch = ({
  isByMe,
  userId,
  status,
  message,
  disabled,
  showEdit,
  chainTop,
  className,
  membersMap,
  showRemove,
  useReaction,
  emojiAllMap,
  chainBottom,
  resendMessage,
  toggleReaction,
  memoizedEmojiListItems,
}) => {
  const { ogMetaData } = message;
  const injectingClassName = Array.isArray(className) ? className : [className];

  const memoizedMessageText = useMemoizedMessageText({
    message: message.message,
    updatedAt: message.updatedAt,
    className: 'sendbird-og-message-word',
  });
  const openLink = () => {
    if (checkOGIsEnalbed(message)) {
      const { url } = ogMetaData;
      window.open(url);
    }
  };

  return (
    <div className={`${OG_MESSAGE} ${OG_MESSAGE}${isByMe ? '--outgoing' : '--incoming'} ${injectingClassName.join(' ')}`}>
      {
        isByMe
          ? (
            <OutgoingOGMessage
              status={status}
              userId={userId}
              message={message}
              disabled={disabled}
              openLink={openLink}
              showEdit={showEdit}
              chainTop={chainTop}
              showRemove={showRemove}
              membersMap={membersMap}
              chainBottom={chainBottom}
              useReaction={useReaction}
              emojiAllMap={emojiAllMap}
              resendMessage={resendMessage}
              toggleReaction={toggleReaction}
              memoizedMessageText={memoizedMessageText}
              memoizedEmojiListItems={memoizedEmojiListItems}
            />
          )
          : (
            <IncomingOGMessage
              userId={userId}
              message={message}
              openLink={openLink}
              chainTop={chainTop}
              membersMap={membersMap}
              chainBottom={chainBottom}
              useReaction={useReaction}
              emojiAllMap={emojiAllMap}
              toggleReaction={toggleReaction}
              memoizedMessageText={memoizedMessageText}
              memoizedEmojiListItems={memoizedEmojiListItems}
            />
          )
      }
    </div>
  );
};

function OutgoingOGMessage(props) {
  const {
    status,
    userId,
    message,
    disabled,
    openLink,
    showEdit,
    chainTop,
    showRemove,
    membersMap,
    chainBottom,
    emojiAllMap,
    useReaction,
    // resendMessage,
    toggleReaction,
    memoizedMessageText,
    memoizedEmojiListItems,
  } = props;
  const {
    ogMetaData,
  } = message;
  const {
    defaultImage,
  } = ogMetaData;
  const MemoizedMessageText = memoizedMessageText;
  const MemoizedEmojiListItems = memoizedEmojiListItems;
  const messageRef = useRef(null);
  const parentRefReactions = useRef(null);
  const parentRefMenus = useRef(null);
  const parentContainRef = useRef(null);

  const isMessageSent = getIsSentFromStatus(status);
  const [mousehover, setMousehover] = useState(false);
  const [moreActive, setMoreActive] = useState(false);

  const showEmojiReactions = (useReaction && message.reactions && message.reactions.length > 0);

  const handleMoreIconClick = () => {
    setMoreActive(true);
  };
  const handleMoreIconBlur = () => {
    setMoreActive(false);
  };

  useMouseHover({
    ref: messageRef,
    setHover: setMousehover,
  });

  return (
    <div
      ref={messageRef}
      className={OUTGOING_OG_MESSAGE}
      style={{
        paddingTop: chainTop ? GROUPING_PADDING : NORAML_PADDING,
        paddingBottom: chainBottom ? GROUPING_PADDING : NORAML_PADDING,
      }}
    >
      <div className={`${OUTGOING_OG_MESSAGE}--inner`}>
        <div className={`${OUTGOING_OG_MESSAGE}--left-padding`}>
          <div className={`${OUTGOING_OG_MESSAGE}__more`} ref={parentContainRef}>
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  className={`${OUTGOING_OG_MESSAGE}__more__menu`}
                  ref={parentRefMenus}
                  width="32px"
                  height="32px"
                  onClick={() => {
                    toggleDropdown();
                    handleMoreIconClick();
                  }}
                  onBlur={() => {
                    handleMoreIconBlur();
                  }}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.MORE}
                    fillColor={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MenuItems
                  parentRef={parentRefMenus}
                  // for catching location(x, y) of MenuItems
                  parentContainRef={parentContainRef}
                  // for toggling more options(menus & reactions)
                  closeDropdown={closeDropdown}
                  openLeft
                >
                  {
                    isMessageSent && (
                      <MenuItem
                        className={`${OUTGOING_OG_MESSAGE}__more__menu__copy`}
                        onClick={() => { copyToClipboard(message.message); closeDropdown(); }}
                      >
                        Copy
                      </MenuItem>
                    )
                  }
                  {
                    isMessageSent && (
                      <MenuItem
                        onClick={() => {
                          if (disabled) { return; }
                          showEdit(true);
                          closeDropdown();
                        }}
                      >
                        Edit
                      </MenuItem>
                    )
                  }
                  <MenuItem
                    onClick={() => {
                      if (disabled) { return; }
                      showRemove(true);
                      closeDropdown();
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuItems>
              )}
            />
            {
              (isMessageSent && useReaction && (emojiAllMap.size > 0))
              && (
                <ContextMenu
                  menuTrigger={(toggleDropdown) => (
                    <IconButton
                      className={`${OUTGOING_OG_MESSAGE}__more__add-reaction`}
                      ref={parentRefReactions}
                      width="32px"
                      height="32px"
                      onClick={toggleDropdown}
                    >
                      <Icon
                        width="24px"
                        height="24px"
                        type={IconTypes.EMOJI_REACTIONS_ADD}
                        fillColor={IconColors.CONTENT_INVERSE}
                      />
                    </IconButton>
                  )}
                  menuItems={(closeDropdown) => (
                    <MemoizedEmojiListItems
                      message={message}
                      parentRef={parentRefReactions}
                      parentContainRef={parentContainRef}
                      closeDropdown={closeDropdown}
                      spaceFromTrigger={{ y: 2 }}
                    />
                  )}
                />
              )
            }
          </div>
          {
            !chainBottom && !(mousehover || moreActive) && (
              <div className={`${OUTGOING_OG_MESSAGE}__message-status`}>
                <MessageStatus
                  message={message}
                  status={status}
                // onDelete={() => { showRemove(true); }}
                // onResend={() => resendMessage(message)}
                // duplicated and should replace to more
                />
              </div>
            )
          }
        </div>
        <div className={`${OUTGOING_OG_MESSAGE}--body`}>
          <div className={`${OUTGOING_OG_MESSAGE}__text-balloon`}>
            {/* {textMatchedWithLinks(message.message)}  */}
            <MemoizedMessageText />
            {/* hoon */}
          </div>
          <div
            className={`${OUTGOING_OG_MESSAGE}__thumbnail ${checkOGIsEnalbed(message) ? '' : `${OUTGOING_OG_MESSAGE}__thumbnail--disabled`}`}
            onClick={openLink}
            onKeyDown={openLink}
            role="button"
            tabIndex={0}
          >
            {
              defaultImage && (
                <ImageRenderer
                  url={defaultImage.url || ''}
                  alt={defaultImage.alt}
                  className={`${OUTGOING_OG_MESSAGE}__thumbnail__image`}
                  width="320px"
                  height="180px"
                  defaultComponent={(
                    <div className={`${OUTGOING_OG_MESSAGE}__thumbnail__image__placeholder`}>
                      <Icon
                        width="56px"
                        height="56px"
                        type={IconTypes.NO_THUMBNAIL}
                      />
                    </div>
                  )}
                />
              )
            }
          </div>
          <div
            className={`${OUTGOING_OG_MESSAGE}__og-tag ${checkOGIsEnalbed(message) ? '' : `${OUTGOING_OG_MESSAGE}__og-tag--disabled`}`}
            onClick={openLink}
            onKeyDown={openLink}
            role="button"
            tabIndex={0}
          >
            {
              ogMetaData.title && (
                <div className={`${OUTGOING_OG_MESSAGE}__og-tag__title`}>
                  <Label type={LabelTypography.SUBTITLE_2} color={LabelColors.ONBACKGROUND_1}>
                    {ogMetaData.title}
                  </Label>
                </div>
              )
            }
            {
              ogMetaData.description && (
                <div className={`${OUTGOING_OG_MESSAGE}__og-tag__description`}>
                  <Label
                    type={LabelTypography.BODY_2}
                    color={LabelColors.ONBACKGROUND_1}
                    className={`${OUTGOING_OG_MESSAGE}__og-tag__description__label`}
                  >
                    {ogMetaData.description}
                  </Label>
                </div>
              )
            }
            {
              ogMetaData.url && (
                <div className={`${OUTGOING_OG_MESSAGE}__og-tag__url`}>
                  <Label
                    type={LabelTypography.CAPTION_3}
                    color={LabelColors.ONBACKGROUND_2}
                    className={`${OUTGOING_OG_MESSAGE}__og-tag__url__label`}
                  >
                    {ogMetaData.url}
                  </Label>
                </div>
              )
            }
            {
              showEmojiReactions
              && (
                <div
                  className={`${OUTGOING_OG_MESSAGE}__og-tag__emoji-reactions--wrapper`}
                  onClick={(event) => event.stopPropagation()}
                  onKeyDown={(event) => event.stopPropagation()}
                  role="button"
                  tabIndex={0}
                >
                  <EmojiReactions
                    className={`${OUTGOING_OG_MESSAGE}__og-tag__emoji-reactions`}
                    userId={userId}
                    message={message}
                    membersMap={membersMap}
                    emojiAllMap={emojiAllMap}
                    toggleReaction={toggleReaction}
                    memoizedEmojiListItems={memoizedEmojiListItems}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

function IncomingOGMessage(props) {
  const {
    userId,
    message,
    openLink,
    chainTop,
    membersMap,
    chainBottom,
    useReaction,
    emojiAllMap,
    toggleReaction,
    memoizedMessageText,
    memoizedEmojiListItems,
  } = props;
  const {
    ogMetaData,
  } = message;
  const {
    defaultImage,
  } = ogMetaData;
  const MemoizedMessageText = memoizedMessageText;
  const MemoizedEmojiListItems = memoizedEmojiListItems;

  const messageRef = useRef(null);
  const avatarRef = useRef(null);
  const parentRefReactions = useRef(null);
  const parentRefMenus = useRef(null);
  const parentContainRef = useRef(null);

  const {
    disableUserProfile,
    renderUserProfile,
  } = React.useContext(UserProfileContext);

  const [mousehover, setMousehover] = useState(false);
  const [moreActive, setMoreActive] = useState(false);

  const showEmojiReactions = (useReaction && message.reactions && message.reactions.length > 0);
  const showReactionAddButton = useReaction && emojiAllMap && (emojiAllMap.size > 0);

  const handleMoreIconClick = () => {
    setMoreActive(true);
  };
  const handleMoreIconBlur = () => {
    setMoreActive(false);
  };

  useMouseHover({
    ref: messageRef,
    setHover: setMousehover,
  });

  return (
    <div
      ref={messageRef}
      className={INCOMING_OG_MESSAGE}
      style={{
        paddingTop: chainTop ? GROUPING_PADDING : NORAML_PADDING,
        paddingBottom: chainBottom ? GROUPING_PADDING : NORAML_PADDING,
      }}
    >
      <div className={`${INCOMING_OG_MESSAGE}--inner`}>
        <div className={`${INCOMING_OG_MESSAGE}--body`}>
          {
            !chainBottom && (
              <ContextMenu
                menuTrigger={(toggleDropdown) => (
                  <Avatar
                    ref={avatarRef}
                    onClick={() => {
                      if (!disableUserProfile) {
                        toggleDropdown();
                      }
                    }}
                    className={`${INCOMING_OG_MESSAGE}__avatar`}
                    src={getSenderProfileUrl(message)}
                    width="28px"
                    height="28px"
                    alt="sender-profile-image"
                  />
                )}
                menuItems={(closeDropdown) => (
                  <MenuItems
                    parentRef={avatarRef}
                    // for catching location(x, y) of MenuItems
                    parentContainRef={avatarRef}
                    // for toggling more options(menus & reactions)
                    closeDropdown={closeDropdown}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    {
                      renderUserProfile
                        ? renderUserProfile({
                          user: message.sender,
                          close: closeDropdown,
                        })
                        : (
                          <UserProfile
                            user={message.sender}
                            onSuccess={closeDropdown}
                          />
                        )
                    }
                  </MenuItems>
                )}
              />
            )
          }
          {
            !chainTop && (
              <Label
                className={`${INCOMING_OG_MESSAGE}__sender-name`}
                type={LabelTypography.CAPTION_2}
                color={LabelColors.ONBACKGROUND_2}
              >
                {getSenderName(message)}
              </Label>
            )
          }
          <div className={`${INCOMING_OG_MESSAGE}__text-balloon`}>
            <MemoizedMessageText />
          </div>
          <div
            className={`${INCOMING_OG_MESSAGE}__thumbnail ${checkOGIsEnalbed(message) ? '' : `${INCOMING_OG_MESSAGE}__thumbnail--disabled`}`}
            onClick={openLink}
            onKeyDown={openLink}
            role="button"
            tabIndex={0}
          >
            {
              defaultImage && (
                <ImageRenderer
                  url={defaultImage.url || ''}
                  alt={defaultImage.alt || ''}
                  className={`${INCOMING_OG_MESSAGE}__thumbnail__image`}
                  width="320px"
                  height="180px"
                  defaultComponent={(
                    <div className={`${INCOMING_OG_MESSAGE}__thumbnail__image__placeholder`}>
                      <Icon
                        width="56px"
                        height="56px"
                        type={IconTypes.NO_THUMBNAIL}
                      />
                    </div>
                  )}
                />
              )
            }
          </div>
          <div
            className={`${INCOMING_OG_MESSAGE}__og-tag ${checkOGIsEnalbed(message) ? '' : `${INCOMING_OG_MESSAGE}__og-tag--disabled`}`}
            onClick={openLink}
            onKeyDown={openLink}
            role="button"
            tabIndex={0}
          >
            {
              ogMetaData.title && (
                <div className={`${INCOMING_OG_MESSAGE}__og-tag__title`}>

                  <Label type={LabelTypography.SUBTITLE_2} color={LabelColors.ONBACKGROUND_1}>
                    {ogMetaData.title}
                  </Label>
                </div>
              )
            }
            {
              ogMetaData.description && (
                <div className={`${INCOMING_OG_MESSAGE}__og-tag__description`}>
                  <Label
                    type={LabelTypography.BODY_2}
                    color={LabelColors.ONBACKGROUND_1}
                    className={`${INCOMING_OG_MESSAGE}__og-tag__description__label`}
                  >
                    {ogMetaData.description}
                  </Label>
                </div>
              )
            }
            {
              ogMetaData.url && (
                <div className={`${INCOMING_OG_MESSAGE}__og-tag__url`}>
                  <Label
                    type={LabelTypography.CAPTION_3}
                    color={LabelColors.ONBACKGROUND_2}
                    className={`${INCOMING_OG_MESSAGE}__og-tag__url__label`}
                  >
                    {ogMetaData.url}
                  </Label>
                </div>
              )
            }
            {
              showEmojiReactions
              && (
                <div
                  className={`${INCOMING_OG_MESSAGE}__og-tag__emoji-reactions--wrapper`}
                  onClick={(event) => event.stopPropagation()}
                  onKeyDown={(event) => event.stopPropagation()}
                  role="button"
                  tabIndex={0}
                >
                  <EmojiReactions
                    className={`${INCOMING_OG_MESSAGE}__og-tag__emoji-reactions`}
                    userId={userId}
                    message={message}
                    membersMap={membersMap}
                    emojiAllMap={emojiAllMap}
                    toggleReaction={toggleReaction}
                    memoizedEmojiListItems={memoizedEmojiListItems}
                  />
                </div>
              )
            }
          </div>
        </div>
        <div className={`${INCOMING_OG_MESSAGE}--right-padding`}>
          {
            !chainBottom && !(mousehover || moreActive) && (
              <Label
                className={`${INCOMING_OG_MESSAGE}__sent-at`}
                type={LabelTypography.CAPTION_3}
                color={LabelColors.ONBACKGROUND_2}
              >
                {getMessageCreatedAt(message)}
              </Label>
            )
          }
          <div className={`${INCOMING_OG_MESSAGE}__more`} ref={parentContainRef}>
            {
              showReactionAddButton
              && (
                <ContextMenu
                  menuTrigger={(toggleDropdown) => (
                    <IconButton
                      ref={parentRefReactions}
                      width="32px"
                      height="32px"
                      onClick={() => {
                        toggleDropdown();
                        handleMoreIconClick();
                      }}
                      onBlur={() => {
                        handleMoreIconBlur();
                      }}
                    >
                      <Icon
                        width="24px"
                        height="24px"
                        type={IconTypes.EMOJI_REACTIONS_ADD}
                        fillColor={IconColors.CONTENT_INVERSE}
                      />
                    </IconButton>
                  )}
                  menuItems={(closeDropdown) => (
                    <MemoizedEmojiListItems
                      parentRef={parentRefReactions}
                      parentContainRef={parentContainRef}
                      closeDropdown={closeDropdown}
                      message={message}
                      spaceFromTrigger={{ y: 2 }}
                    />
                  )}
                />
              )
            }
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  ref={parentRefMenus}
                  width="32px"
                  height="32px"
                  onClick={() => {
                    toggleDropdown();
                    handleMoreIconClick();
                  }}
                  onBlur={() => {
                    handleMoreIconBlur();
                  }}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.MORE}
                    fillColor={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MenuItems
                  parentRef={parentRefMenus}
                  parentContainRef={parentContainRef}
                  closeDropdown={closeDropdown}
                >
                  <MenuItem
                    className={`${INCOMING_OG_MESSAGE}__more__menu__copy`}
                    onClick={() => { copyToClipboard(message.message); closeDropdown(); }}
                  >
                    Copy
                  </MenuItem>
                </MenuItems>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OGMessageSwitch;

const noop = () => { };

OGMessageSwitch.propTypes = {
  isByMe: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    message: PropTypes.string,
    sender: PropTypes.shape({}),
    ogMetaData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      defaultImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    reactions: PropTypes.array,
    updatedAt: PropTypes.number,
  }).isRequired,
  useReaction: PropTypes.bool.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  status: PropTypes.string,
  disabled: PropTypes.bool,
  showEdit: PropTypes.func,
  showRemove: PropTypes.func,
  resendMessage: PropTypes.func,
  toggleReaction: PropTypes.func,
  membersMap: PropTypes.instanceOf(Map),
  emojiAllMap: PropTypes.instanceOf(Map),
  memoizedEmojiListItems: PropTypes.func,
  chainTop: PropTypes.bool,
  chainBottom: PropTypes.bool,
};

OGMessageSwitch.defaultProps = {
  className: '',
  status: '',
  disabled: false,
  showEdit: noop,
  showRemove: noop,
  resendMessage: noop,
  toggleReaction: noop,
  membersMap: new Map(),
  emojiAllMap: new Map(),
  memoizedEmojiListItems: noop,
  chainTop: false,
  chainBottom: false,
};

OutgoingOGMessage.propTypes = {
  status: PropTypes.string,
  userId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    message: PropTypes.string,
    ogMetaData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      defaultImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    reactions: PropTypes.array,
    updatedAt: PropTypes.number,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  openLink: PropTypes.func.isRequired,
  showEdit: PropTypes.func.isRequired,
  showRemove: PropTypes.func.isRequired,
  membersMap: PropTypes.instanceOf(Map).isRequired,
  emojiAllMap: PropTypes.instanceOf(Map).isRequired,
  useReaction: PropTypes.bool.isRequired,
  // resendMessage: PropTypes.func.isRequired,
  toggleReaction: PropTypes.func.isRequired,
  memoizedMessageText: PropTypes.func.isRequired,
  memoizedEmojiListItems: PropTypes.func.isRequired,
  chainTop: PropTypes.bool.isRequired,
  chainBottom: PropTypes.bool.isRequired,
};

OutgoingOGMessage.defaultProps = {
  status: '',
};

IncomingOGMessage.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    message: PropTypes.string,
    sender: PropTypes.shape({}),
    ogMetaData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      url: PropTypes.string,
      defaultImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    reactions: PropTypes.array,
    updatedAt: PropTypes.number,
  }).isRequired,
  openLink: PropTypes.func.isRequired,
  membersMap: PropTypes.instanceOf(Map).isRequired,
  emojiAllMap: PropTypes.instanceOf(Map).isRequired,
  useReaction: PropTypes.bool.isRequired,
  toggleReaction: PropTypes.func.isRequired,
  memoizedMessageText: PropTypes.func.isRequired,
  memoizedEmojiListItems: PropTypes.func.isRequired,
  chainTop: PropTypes.bool.isRequired,
  chainBottom: PropTypes.bool.isRequired,
};
