import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import * as utils from './utils';

import Avatar from '../Avatar';
import UserProfile from '../UserProfile';
import Label, { LabelTypography, LabelColors, LabelStringSet } from '../Label';
import MessageStatus from '../MessageStatus';
import ContextMenu, { MenuItems, MenuItem } from '../ContextMenu';
import IconButton from '../IconButton';
import Icon, { IconColors, IconTypes } from '../Icon';
import { UserProfileContext } from '../../lib/UserProfileContext';
import useMouseHover from '../../hooks/onMouseHover';

const CLASS_NAME = 'sendbird-unknown-message';
const GROUPING_PADDING = '1px';
const NORMAL_PADDING = '8px';

export default function UnknownMessage({
  message,
  isByMe,
  status,
  className,
  showRemove,
  chainTop,
  chainBottom,
}) {
  const injectingClassName = Array.isArray(className) ? className : [className];
  injectingClassName.unshift(CLASS_NAME);
  injectingClassName.push(`${CLASS_NAME}${isByMe ? '--outgoing' : '--incoming'}`);

  return (
    <div className={injectingClassName.join(' ')}>
      {
        isByMe
          ? (
            <OutgoingUnknownMessage
              status={status}
              message={message}
              chainTop={chainTop}
              showRemove={showRemove}
              chainBottom={chainBottom}
            />
          )
          : (
            <IncomingUnknownMessage
              message={message}
              chainTop={chainTop}
              chainBottom={chainBottom}
            />
          )
      }
    </div>
  );
}

UnknownMessage.propTypes = {
  message: PropTypes.shape({}).isRequired,
  isByMe: PropTypes.bool,
  status: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  showRemove: PropTypes.func,
  chainTop: PropTypes.bool,
  chainBottom: PropTypes.bool,
};

UnknownMessage.defaultProps = {
  isByMe: false,
  status: '',
  className: '',
  showRemove: () => { },
  chainTop: false,
  chainBottom: false,
};

function OutgoingUnknownMessage({
  message,
  status,
  showRemove,
  chainTop,
  chainBottom,
}) {
  const className = 'sendbird-outgoing-unknown-message';
  const messageRef = useRef(null);
  const parentContainRef = useRef(null);
  const menuRef = useRef(null);
  const [mousehover, setMousehover] = useState(false);
  const [moreActive, setMoreActive] = useState(false);

  const handleMoreIconClick = () => {
    setMoreActive(true);
  };
  const handleMoreIconBlur = () => {
    setMoreActive(false);
  };

  useMouseHover({
    ref: messageRef,
    setHover: setMousehover,
  });

  return (
    <div
      ref={messageRef}
      className={className}
      style={{
        paddingTop: chainTop ? GROUPING_PADDING : NORMAL_PADDING,
        paddingBottom: chainBottom ? GROUPING_PADDING : NORMAL_PADDING,
      }}
    >
      <div className={`${className}--inner`}>
        <div className={`${className}--left-padding`}>
          <div
            className={`${className}__more`}
            ref={parentContainRef}
          >
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  className={`${className}__more__menu`}
                  ref={menuRef}
                  width="32px"
                  height="32px"
                  onClick={() => {
                    toggleDropdown();
                    handleMoreIconClick();
                  }}
                  onBlur={() => {
                    handleMoreIconBlur();
                  }}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.MORE}
                    fillColor={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MenuItems
                  parentRef={menuRef}
                  // for catching location(x, y) of MenuItems
                  parentContainRef={parentContainRef}
                  // for toggling more options(menus & reactions)
                  closeDropdown={closeDropdown}
                  openLeft
                >
                  <MenuItem
                    onClick={() => { showRemove(true); closeDropdown(); }}
                  >
                    Delete
                  </MenuItem>
                </MenuItems>
              )}
            />
          </div>
          {
            !chainBottom && !(mousehover || moreActive) && (
              <div className={`${className}__message-status`}>
                <MessageStatus
                  message={message}
                  status={status}
                />
              </div>
            )
          }
        </div>
        <div className={`${className}__body`}>
          <div className={`${className}__body__text-balloon`}>
            <Label
              className={`${className}__body__text-balloon__header`}
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_1}
            >
              {LabelStringSet.UNKNOWN__UNKNOWN_MESSAGE_TYPE}
            </Label>
            <Label
              className={`${className}__body__text-balloon__description`}
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_2}
            >
              {LabelStringSet.UNKNOWN__CANNOT_READ_MESSAGE}
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}

function IncomingUnknownMessage({
  message,
  chainTop,
  chainBottom,
}) {
  const className = 'sendbird-incoming-unknown-message';
  const { sender } = message;
  const avatarRef = useRef(null);
  const {
    disableUserProfile,
    renderUserProfile,
  } = React.useContext(UserProfileContext);
  return (
    <div
      className={className}
      style={{
        paddingTop: chainTop ? GROUPING_PADDING : NORMAL_PADDING,
        paddingBottom: chainBottom ? GROUPING_PADDING : NORMAL_PADDING,
      }}
    >
      <div className={`${className}--inner`}>
        <div className={`${className}__left`}>
          {
            !chainBottom && (
              <ContextMenu
                menuTrigger={(toggleDropdown) => (
                  <Avatar
                    ref={avatarRef}
                    onClick={() => {
                      if (!disableUserProfile) {
                        toggleDropdown();
                      }
                    }}
                    className={`${className}__left__sender-profile-image`}
                    src={sender.profileUrl}
                    width="28px"
                    height="28px"
                    alt="sender-profile-image"
                  />
                )}
                menuItems={(closeDropdown) => (
                  <MenuItems
                    parentRef={avatarRef}
                    // for catching location(x, y) of MenuItems
                    parentContainRef={avatarRef}
                    // for toggling more options(menus & reactions)
                    closeDropdown={closeDropdown}
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    {
                      renderUserProfile
                        ? renderUserProfile({
                          user: message.sender,
                          close: closeDropdown,
                        })
                        : (
                          <UserProfile
                            user={message.sender}
                            onSuccess={closeDropdown}
                          />
                        )
                    }
                  </MenuItems>
                )}
              />
            )
          }
        </div>
        <div className={`${className}__body`}>
          {
            !chainTop && (
              <Label
                className={`${className}__body__sender-name`}
                type={LabelTypography.CAPTION_2}
                color={LabelColors.ONBACKGROUND_2}
              >
                {sender.nickname || LabelStringSet.NO_NAME}
              </Label>
            )
          }
          <div className={`${className}__body__text-balloon`}>
            <Label
              className={`${className}__body__text-balloon__header`}
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_1}
            >
              {LabelStringSet.UNKNOWN__UNKNOWN_MESSAGE_TYPE}
            </Label>
            <Label
              className={`${className}__body__text-balloon__description`}
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_2}
            >
              {LabelStringSet.UNKNOWN__CANNOT_READ_MESSAGE}
            </Label>
          </div>
        </div>
        <div className={`${className}--right-padding`}>
          {
            !chainBottom && (
              <Label
                className={`${className}__sent-at`}
                type={LabelTypography.CAPTION_3}
                color={LabelColors.ONBACKGROUND_2}
              >
                {utils.getMessageCreatedAt(message)}
              </Label>
            )
          }
        </div>
      </div>
    </div>
  );
}

OutgoingUnknownMessage.propTypes = {
  message: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
  showRemove: PropTypes.func,
  chainTop: PropTypes.bool.isRequired,
  chainBottom: PropTypes.bool.isRequired,
};

OutgoingUnknownMessage.defaultProps = {
  showRemove: () => { },
};

IncomingUnknownMessage.propTypes = {
  message: PropTypes.shape({
    sender: PropTypes.shape({
      nickname: PropTypes.string,
      profileUrl: PropTypes.string,
    }),
  }).isRequired,
  chainTop: PropTypes.bool.isRequired,
  chainBottom: PropTypes.bool.isRequired,
};
